import React, { useEffect } from "react";
import {
  Grid,
  Container,
  Box,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import WritingsList from "./WritingsList";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  writingsList: {
    paddingLeft: "50px",
    width: "100%",
    "@media (max-width:1279px)": {
      paddingLeft: "0px",
      marginTop: "20px",
    },
  },
  horizontalRow: {
    width: "100%",
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  ListDetail: {
    paddingTop: "24px",
    paddingBottom: "96px",
    "@media (max-width:1237px)": {
      paddingBottom: "45px",
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.secondary.main,
    },
    "& a:hover": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));
const Writings = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = useStyles();
  return (
    <Box pt="90px" pl={5} pr={5}>
      <Container maxWidth="md">
        <Grid container>
          <Grid item lg={3}>
            <Typography variant="h3">Read my blog</Typography>
            <br></br>
            <Typography variant="body2">
              I write about what's on my mind, around all things data, ai and
              automation
            </Typography>
          </Grid>
          <Grid container item lg={9} className={classes.writingsList}>
            {WritingsList &&
              WritingsList.map((list2) => (
                <>
                  <hr className={classes.horizontalRow}></hr>
                  <Box className={classes.ListDetail}>
                    <Link to="/writings/the-benefits-of-hiring-a-consultant-instead-of-an-employee-or-an-agency">
                      <Grid container item lg={12}>
                        <Grid item lg={6}>
                          <Box display="flex" flexDirection="column" pr={3}>
                            <Typography variant="subtitle1">
                              {list2.date}
                            </Typography>
                            <Typography variant="h6">{list2.title}</Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={6}>
                          <Typography variant="body2">{list2.desc}</Typography>
                        </Grid>
                      </Grid>
                    </Link>
                  </Box>
                </>
              ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default Writings;
