/*eslint-disable*/
import React from 'react';
/*eslint-disable*/
import { Link } from 'react-router-dom';
// nodejs library that concatenates classes
// nodejs library to set properties for components
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Box } from '@material-ui/core';

import { ThemeTypography } from '../ThemeComponents';

const useStyles = makeStyles((theme) => ({
  footer: {
    '& .MuiSvgIcon-root': {
      height: '1.3em',
      width: '1.3em',
      [theme.breakpoints.down('sm')]: {
        height: '1.1em',
        width: '1.1em',
      },
    },
  },
  navLink: {
    color: theme.palette.text.primary,
    position: 'relative',
    padding: '0.45rem 0.9375rem',
    letterSpacing: '-0.2px',
    borderRadius: '3px',
    lineHeight: '1.25rem',
    textDecoration: 'none',
    margin: '0px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 30px)',
      padding: '1.2rem 0',
      textAlign: 'left',
      '& > span:first-child': {
        justifyContent: 'flex-start',
      },
    },
  },
}));

const SubFooter = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl" className={classes.footer}>
      <Box pt={3} pb={5}>
        <Grid container direction="row" wrap="nowrap">
          <Grid container direction="row" wrap="nowrap" alignItems="center">
            <ThemeTypography color="white" variant="subtitle2">
              &#xa9; &nbsp;2010 - 2021&nbsp;&nbsp;
              <Link to="privacy" className={classes.navLink}>
                {' '}
              </Link>
            </ThemeTypography>
          </Grid>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justify="flex-end"
            alignItems="center"
          >
            <Instagram/>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SubFooter;
