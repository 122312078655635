const HomeList2 = [
  {
    number: '01',
    title: 'Make confident decisions',
    desc:
      'Base your decisions on real data, and not on assumptions. Validation may be needed before moving forward. Even when businesses have been using a strategy for a while, they may suddenly face an uncertain world. This could be launching a new product, entering a new market, overcoming bad press, or the market changing suddenly and you need to adapt quickly.',
  },
  {
    number: '02',
    title: 'Understand the latest in data intelligence',
    desc:
      'It is difficult to stay up to date with the latest trends and tools. Learn through us, tailored for your business, without the jargon.',
  },
  {
    number: '03',
    title: 'Increase your revenue',
    desc:
      "A data scientist examines and explores the organization’s data, after which they recommend and prescribe certain actions that will help improve the institution’s performance, better engage customers, and ultimately increase profitability.",
  },
  {
    number: '04',
    title: 'Challenge staff to think data',
    desc:
      'One of the responsibilities of a data scientist is to ensure that the staff is familiar and well-versed with the organization’s analytics product. They prepare the staff for success with the demonstration of the effective use of the system to extract insights and drive action. Once the staff understands the product capabilities, their focus can shift to addressing key business challenges.',
  },
];

export default HomeList2;
