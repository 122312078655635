/*eslint-disable*/
import React from 'react';
/*eslint-disable*/
import { Link } from 'react-router-dom';
// nodejs library that concatenates classes
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Container,
  List,
  ListItem,
  Box,
  Typography,
} from '@material-ui/core';
// core components
import { ThemeTypography } from '../ThemeComponents';

import LinksList from '../Header/LinksList';

const useStyles = makeStyles((theme) => ({
  list: {
    margin: 0,
    paddingLeft: '0',
    listStyle: 'none',
    paddingTop: '0',
    paddingBottom: 0,
    overflow: 'auto',
    ['@media (max-width:1279px)']: {
      '& .MuiListItem-root': {
        paddingLeft: '0px',
      },
    },
  },

  active: {
    '& a': {
      //fontWeight: 800,
      //fontSize: '1.125rem',
      color: theme.palette.text.main,
    },
  },
  navLink: {
    color: theme.palette.text.primary,
    position: 'relative',
    padding: '0.45rem 0.9375rem',
    fontWeight: '600',
    letterSpacing: '-0.2px',
    fontSize: '29px',
    borderRadius: '3px',
    lineHeight: '1.25rem',
    textDecoration: 'none',
    margin: '0px',
    display: 'block',
    paddingLeft: 0,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '23px',
    },
  },
  box3: { justifyContent: 'flex-end' },
  [theme.breakpoints.down('sm')]: {
    box1: { order: 3, marginTop: '20px' },
    box2: { order: 1 },
    box3: { order: 2, justifyContent: 'flex-start', marginTop: '20px' },
  },
  contactInfo: {
    ['@media (max-width:1279px)']: {
      '& .MuiListItem-root': {
        paddingLeft: '0px',
      },
    },
  },
  contactMain: {
    ['@media (max-width:1279px)']: {
      paddingTop: '30px',
    },
  },
  footerText: {
    maxWidth: '21.25rem',
  },
}));

const MainFooter = () => {
  const classes = useStyles();
  return (
    <Container  maxWidth="xl">
      <Grid container direction="row" wrap="wrap" justifyContent="center">
        <Grid item lg={4} md={12} sm={12} className={classes.contactMain}>
          <Grid
            container
            spacing={2}
            direction="row"
            wrap="wrap"
            className={classes.footerText}
          >
            <Grid item>
              <Typography variant="body2">
                <Link className={classes.navLink}> Say hello</Link>
                <br />
                If you wish to see some of the previous work that I have done,
                please reach out and I would be happy to share.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={4} md={12} sm={12} className={classes.contactMain}>
          <Grid container>
            <Grid item>
              <List
                className={classNames({
                  [classes.list]: true,
                })}
              >
                {LinksList.map((item, index) => (
                  <ListItem
                    className={classNames({
                      [classes.active]: item.url === location.pathname,
                    })}
                    key={index}
                  >
                    <Link to={item.url} className={classes.navLink}>
                      {item.name}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={4} sm={12} sm={12} className={classes.contactMain}>
          <div className={classes.contactInfo}>
            <ListItem>
              <Link className={classes.navLink}>info@ergonanalyticscc.com</Link>
            </ListItem>
            <ListItem>
              <Link className={classes.navLink}>(+264) 81214 3049</Link>
            </ListItem>
            <ListItem>
              <Link className={classes.navLink}>LinkedIn</Link>
            </ListItem>
            <ListItem>
              <Link className={classes.navLink}>Instagram</Link>
            </ListItem>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MainFooter;
