const white = '#FFFFFF';
const black = '#193c48';
const primary = '#ff5252 ';
const secondaryText = '#517072';
// const primaryLighten = "";

const theme =  {
  palette: {
    common: {
      black,
      white,
      secondaryText,
      primary,
    },
    primary: {
      contrastText: white,
      main: primary,
    },
    secondary: {
      contrastText: white,
      main: black,
    },
    background: {
      contrastText: white,
      main: white,
    },
    text: {
      contrastText: white,
      main: secondaryText,
    },
  },
  overrides: {
    // MuiSvgIcon: {
    //   root: {
    //     color: Gray,
    //   },
    // },
  },
};
export default theme;