import defaultTheme from './default';
import { createMuiTheme } from '@material-ui/core/styles';
let theme = createMuiTheme();

const overrides = {
  typography: {
    fontSize: 16,
    htmlFontSize: 16,
    fontWeight: 400,
    lineHeight: '20px',
    h1: {
      fontSize: '92px',
      lineHeight: '1em',
      fontWeight: 700,
      color: defaultTheme.palette.secondary,
      // [theme.breakpoints.down('lg')]: {
      //   fontWeight: 700,
      //   fontSize: '52px',
      //   letterSpacing: '-0.5px',
      //   lineHeight: '1em',
      // },
      '@media (max-width:1238px)': {
        //991px
        fontWeight: 700,
        fontSize: '66px',
        lineHeight: '1em',
      },
      '@media (max-width:958px)': {
        fontWeight: 700,
        fontSize: '72px',
        lineHeight: '1em',
      },
      '@media (max-width:598px)': {
        fontWeight: 700,
        fontSize: '40px',
        lineHeight: '1em',
      },
    },

    h2: {
      fontWeight: 600,
      fontSize: '59px',
      lineHeight: '1.2em',
      overflowWrap: 'break-word',
      color: defaultTheme.palette.secondary.main,
      '@media (max-width:1238px)': {
        fontWeight: 600,
        fontSize: '40px',
        lineHeight: '1.2em',
      },
      '@media (max-width:598px)': {
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '1.2em',
      },
    },
    h3: {
      fontSize: '30px',
      fontWeight: 600,
      lineHeight: '1.4em',
      '@media (max-width:598px)': {
        fontWeight: 600,
        fontSize: '22px',
        lineHeight: '1.4rem',
      },
    },
    h6: {
      fontSize: '38px',
      fontWeight: 600,
      lineHeight: '1.3em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '27px',
        fontWeight: 600,
        lineHeight: '1.3em',
      },
    },
    // h6: {
    //   color: defaultTheme.palette.text.secondary,
    //   fontWeight: 300,
    //   fontSize: '14px',
    //   letterSpacing: '-0.05px',
    //   lineHeight: '20px'
    // },

    // subtitle2: {
    //   color: defaultTheme.palette.text.secondary,
    //   fontWeight: 200,
    //   fontSize: '',
    //   letterSpacing: '-0.5px',
    //   lineHeight: '',
    // },
    body1: {
      color: defaultTheme.palette.text.main,
      fontSize: '30px', //24
      lineHeight: '1.5em',
      fontWeight: 400,
      '@media (max-width:1238px)': {
        color: defaultTheme.palette.text.main,
        fontSize: '25px', //24
        lineHeight: '1.5em',
        fontWeight: 400,
      },

      //   [theme.breakpoints.down('md')]: {
      //     color: defaultTheme.palette.text.main,
      //     fontSize: '23px',
      //     lineHeight: '1.5em',
      //   },
    },
    body2: {
      fontSize: '23px',
      fontWeight: 400,
      lineHeight: '1.5em',
    },
    subtitle1: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '1.4em',
    },
    h5: {
      fontSize: '10vh',
      lineHeight: '1em',
      fontWeight: 700,
      color: defaultTheme.palette.secondary,
      '@media (max-width:1238px)': {
        fontWeight: 700,
        fontSize: '9vh',
        lineHeight: '1em',
      },
      '@media (max-width:958px)': {
        fontWeight: 700,
        fontSize: '8vh',
        lineHeight: '1em',
      },
      '@media (max-width:593px)': {
        fontWeight: 700,
        fontSize: '6vh',
        lineHeight: '1em',
      },
    },
    // button: {
    //   fontWeight: 700,
    //   fontSize: '1rem',
    //   lineHeight: 1.75,
    //   textTransform: 'uppercase',
    // },
    // overline: {
    //   fontFamily: 'Merriweather',
    //   fontStyle: 'italic',
    //   fontWeight: 400,
    //   fontSize: '24px',
    //   lineHeight: '140%',
    //   color: defaultTheme.palette.grey[900],
    //   textTransform: 'unset',
    // },
  },
};

const MuiTheme =  {
  default: createMuiTheme({ ...overrides, ...defaultTheme }),
};

export default MuiTheme;