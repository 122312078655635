const CaseStudiesList = [
  {
    title: "Verime",
    desc: "AI meets identity management. Verime is an identity management platform connecting clients to their customers for a seamless transfer of identity information. As a company that is passionate about automation and digital transformation, we want to ensure long queues and stacks of paper are a thing of the past. <br /> <br /> On the app, users upload and save documents. On the web, portal businesses can request for a document, for a signature on a form or a video meeting with our users. <br /> <br /> In the fast progressing world of today, more and more companies are shifting to digital, and it's high time that the identity management process is automated. Not only are conventional ways of onboarding customers becoming obsolete, but they are also becoming a big cost burden for every business. With Verime, customers and businesses alike will have time and, ultimately, money savings.",
  },
  {
    title: "Dely",
    desc: "Dely is a delivery application and website that aims to change the way restaurants and wholesalers communicate. <br /> <br /> When a chef runs out of ingredients, they can immediately order from a wholesaler at a click of a button. Dely facilitates the relationship between kitchens and suppliers, making the process as easy as possible. Delay also provides the technology for suppliers to analyse order patterns and promote new fresh produce.",
  },
  {
    title: "Lefa",
    desc: "Lefa wanted to gain insights from their trove of data to better serve their customers. <br /> <br /> This required data collection, cleaning, wrangling into various kinds of data types and analysis to get insights into customer behaviour. One such insight was that we worked out the best location for taxis at any given time of day which had the potential for few fuel savings.",
  },
];

export default CaseStudiesList;
