import React from 'react';
import {
  Container,
  List,
  ListItem,
  Box,
  Typography,
  ListItemIcon,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  contactMain: {
    width: '60%',
    '@media (max-width:1300px)': {
      width: '70%',
    },
    '@media (max-width:1250px)': {
      width: '80%',
    },
    '@media (max-width:900px)': {
      width: '100%',
    },
  },
  color: {
    color: theme.palette.common.black,
  },
}));

const CaseStudiesDetail = () => {
  const classes = useStyles();
  return (
    <Box pt="90px">
      <Container maxWidth="md">
        <Box>
          <Typography variant="h1">
            Verime: Providing an AI enabled identity management platform.
          </Typography>

          <Box pb={2} pt={5}>
            <Typography variant="body1">
              In the pandemic disrupted era, an identity managent platform that connects businesses to their customers in 
              a seamless and remote seemed like a natural market opening. At Ergo Analytics we worked on an app that aimed to 
              satisfy that demand. Users are able to upload documents, businesses then request for access to these documents and
              users choose to accept or reject. 
            </Typography>
          </Box>
          <Box pb={2}>
            <Typography variant="body1">
              Verime is going for convinience. For a world with less paper and long queues. For a more secure interaction between users and businesses.
            </Typography>
          </Box>
          <Box pb={3}>
            <Typography variant="body1">
              The result of the audit also highlighted their need for:
            </Typography>
          </Box>
          <Box mb={3}>
            <List dotted>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="body1">
                  A personalised SEO guide based on their current web structure.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="body1">
                  An evaluation of their users' journey, providing them with
                  useful data and insights for their planned website redesign.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="body1">
                  An evaluation of their social media strategy, and
                  recommendations on how to further reach their two very
                  distinct audiences — businesses and talent. Bringing attention
                  to one of their social media channels, as they were investing
                  too much time into it and receiving low return.
                </Typography>
              </ListItem>
            </List>
          </Box>
          <Box pb={10}>
            <Typography variant="body1">
              This was a great first step to help the marketing team move
              forward and ensure that they are staying ahead of their
              competition.
            </Typography>
          </Box>
          <Box pb={3}>
            <Typography variant="body1">
              As the audit has just been completed, there is no data yet
              showcasing results of these recommendations. This case study will
              be updated once enough data is collected.
            </Typography>
          </Box>

          <hr></hr>
          <Box pb={3}>
            <Typography variant="body1" className={classes.color}>
              <b>Kind words from the client</b>
            </Typography>
          </Box>
          <Box pb={3}>
            <Typography variant="body1">
              Ninia provided great value through the audit of the digital
              marketing for our business in Malta and UK. As a marketeer who is
              a fan of strategic methodologies, it was a great experience to be
              part of a strategy and now implementation of a results-driven
              project.
            </Typography>
          </Box>
          <Box pb={3}>
            <Typography variant="body2">
              <b>Charlotte Seymour | Head of Marketing </b>
            </Typography>
          </Box>
          <Box pb={3}>
            <Typography variant="body2">
              <b>Boston Link</b>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default CaseStudiesDetail;
