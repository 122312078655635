import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home/Home";
import CaseStudies from "./pages/CaseStudies/CaseStudies";
import Contact from "./pages/Contact/Contact";
import Writings from "./pages/Writings/Writings";
import WritingsDetail from "./pages/Writings/components/WritingsDetail";
import CaseStudiesDetail from "./pages/CaseStudies/components/CaseStudiesDetail";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainApp: {
    backgroundColor: theme.palette.common.white,
  },
  app: {
    paddingTop: "130px",
    marginBottom: "130px",
  },
}));
export default function App() {
  const classes = useStyles();
  return (
    <Router>
      <div id="App" className={classes.mainApp}>
        <Header />
        <div className={classes.app}>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/home" />} />
            <Route exact path="/home" component={Home} />
            <Route path="/about" component={Contact} />
            <Route path="/case_studies" component={CaseStudies} />
            <Route exact path="/writings" component={Writings} />
            <Route
              exact
              path="/writings/the-benefits-of-hiring-a-consultant-instead-of-an-employee-or-an-agency"
              component={WritingsDetail}
            ></Route>
            <Route
              exact
              path="/case-studies/boston-link-digital-audit"
              component={CaseStudiesDetail}
            ></Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}
