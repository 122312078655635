import React, { useEffect } from "react";
import {
  Grid,
  Container,
  Box,
  Typography,
} from "@material-ui/core";

// import { makeStyles } from "@material-ui/core/styles";
// const useStyles = makeStyles((theme) => ({
//   contactMain: {
//     width: "60%",
//     "@media (max-width:1300px)": {
//       width: "70%",
//     },
//     "@media (max-width:1250px)": {
//       width: "80%",
//     },
//     "@media (max-width:900px)": {
//       width: "100%",
//     },
//   },
// }));

const WritingsDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const classes = useStyles();
  return (
    <Box pt="90px" pl={5} pr={5}>
      <Container maxWidth="md">
        <Box>
          <Typography variant="h1">
            Why Machine Learning Fairness is Important in Marketing
          </Typography>
          <Box mt={2}>
            <Typography variant="body1">November 4, 2021</Typography>
          </Box>

          {/* <img
            src="/assests/image2.jpg"
            style={{ width: "100%", paddingTop: "40px", paddingBotton: "15px" }}
          ></img> */}
          <Box pb={2}>
            <Typography variant="body1">
              Have you ever browsed for a product and later saw that same
              product in a sponsored ad or watched a video on YouTube just
              because it was in the recommended videos? Have you ever wondered
              how the content that appeals to you always seems to find its way
              to you?
            </Typography>
          </Box>
          <Box pb={2}>
            <Typography variant="body1">
              Well, as it turns out, this is due to the machine learning
              algorithms that are running behind the scenes of the internet
              which are trained specifically to scour users' data from their
              browsing history, watched content, clickstream data, etc. Users
              are well aware that organizations like Facebook, Google, YouTube,
              etc. show sponsored content based on their online activity. These
              custom ads generated by machine learning algorithms are highly
              user-centered, and this is how businesses build users’ trust and
              persuade them to buy their products.
            </Typography>
          </Box>
          <Box pb={3}>
            <Typography variant="h6">
              Are Machine Learning Algorithms Biased?
            </Typography>
          </Box>
          {/* <Box pb={3}>
            <Typography variant="h3">
              Need someone skilled, but low on budget
            </Typography>
          </Box> */}
          <Box pb={3}>
            <Typography variant="body1">
              The machine learning algorithms used for ads can often be biased
              in the ads they show. Reputable brands like Google take this lack
              of fairness very seriously. They believe that it takes years of
              building trust and one wrong decision by the machine learning
              algorithm can sabotage this trust.
            </Typography>
          </Box>
          <Box pb={3}>
            <Typography variant="body1">
              To better understand the effect of this bias perceived by users,
              Google conducted several surveys in which people were asked about
              the problem. The answers of the participants varied but the
              majority argued that the algorithms made false assumptions about
              them. These false assumptions can offend users and deter them from
              using the service the brand provides. The outcome of the survey
              highlighted the fact that an accurate assumption based on
              inclusive data builds trust, whereas biased data destroys it.
            </Typography>
          </Box>
          <Box pb={3}>
            <Typography variant="h3">
              What is Inclusive Data and Why is it Needed?
            </Typography>
          </Box>
          <Box pb={3}>
            <Typography variant="body1">
              Inclusive data is the thorough consideration of everything from
              the lowest to the upper most limits of all information collected.
              The need for inclusive data for ads is necessary for showing
              relevant content. It is a fact that no two people have the same
              interests, even if they have other things in common such as gender
              or race. People argued that they were being shown content based on
              assumptions about them by the machine learning algorithm and not
              based on what they actually prefer. This is why the use of
              inclusive data is necessary for businesses to gain the trust of
              their consumers.
            </Typography>
          </Box>

          <Box pb={3}>
            <Typography variant="h3">What is Data Bias?</Typography>
          </Box>
          <Box pb={3}>
            <Typography variant="body1">
              When a machine learning model is trained with data that is more
              inclined towards one aspect of something but does not consider
              other aspects, its learnings are biased towards that one aspect.
              For example, if a woman sees ads related to jewelry and clothes
              while her interests lie in different domains such as health and
              fitness, then there is little chance that she will click on the
              ad; instead, she would consider the ad spam. Undesirable ads are
              annoying and often drive users away from such brands, whereas the
              actual aim of these algorithms is to help consumers look for the
              things they are interested in.
              <br />
              So to fix this data bias, we need to fix the first step in the
              machine learning pipeline ‒ the data being fed into the pipeline
              that in turn makes the decisions. Experts often relate this with
              the kitchen metaphor. If you want to cook a meal, the first and
              foremost step is to gather the necessary ingredients. No matter
              how good your kitchen is or how advanced your tools are, you
              cannot cook the desired meal without the required ingredients. So,
              the main focus should be to collect and use inclusive data with
              the help of crowdsourcing solutions and regular audits by the
              brands. <br />
              Machine learning algorithms are not capable of critical thinking
              and thus are not able to recognize offensive stereotypes. If a
              model is trained on the majority dataset of men and it receives
              positive feedback from the majority of the male population, then,
              according to the feedback, it would calculate that it performed
              well. However, the model is heavily biased towards the male
              population. To fix such biases, it is up to talented machine
              learning engineering teams to find the issue and take the
              necessary steps to fix it. It is also important that the teams are
              diverse enough to equip the model with the ability to detect
              unique circumstances to avoid any biases.
            </Typography>
          </Box>

          <Box pb={3}>
            <Typography variant="h3">Conclusion</Typography>
          </Box>
          <Box pb={3}>
            <Typography variant="body1">
              Machine learning fairness is vital in displaying suitable content.
              It takes years and years of great service to build a consumer’s
              trust in a brand. One unfair bias can ruin this trust. Brands and
              consumers both care about the model’s fairness and both are
              affected by such flaws. To conserve this trust between brands and
              consumers, Google listened to its users and what they had to say.
              From several conducted surveys, they came to the conclusion that
              people from different demographics do not like to be shown
              stereotypical ads. Users showed objection to the content shown
              based on their demographical characteristics. On the other hand,
              consumers mentioned that when they saw accurate ads based on their
              preferences, it was very pleasing to see.
            </Typography>
          </Box>

          <Box pb={3}>
            <Typography variant="body1">
              Measures to improve data fairness are being taken by companies.
              The steps are highly iterative and involve a lot of testing and
              then fine-tuning based on the results. Another crucial step in
              improving the models' fairness is to build a machine learning team
              with a diverse background so that the data becomes more robust and
              less biased. When the model is ready, it can then be tested
              thoroughly based on questions relating to the type, quantity,
              quality, labeling, diversity, and scope of the data. Making sure
              that the data is robust in each domain can result in an accurate
              and fair model.
            </Typography>
          </Box>

          <hr></hr>
          <Box>
            <Grid container>
              <Grid item lg={6}>
                <Typography variant="subtitle1">November 4, 2021</Typography>
                <Typography variant="h6">
                  Why Machine Learning Fairness is Important in Marketing
                </Typography>
              </Grid>
              {/* <Grid item lg={6}>
                <Typography variant="body2">
                  Hiring a consultant can offer a number of benefits such as;
                  validation, resources, different perspective and providing
                  certainty in an uncertain world.
                </Typography>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default WritingsDetail;
