import React, { useEffect } from "react";
import {
  Container,
  Box,
  Typography,
} from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// const useStyles = makeStyles((theme) => ({
//   contactMain: {
//     width: "60%",
//     "@media (max-width:1300px)": {
//       width: "70%",
//     },
//     "@media (max-width:1250px)": {
//       width: "80%",
//     },
//     "@media (max-width:900px)": {
//       width: "100%",
//     },
//   },
// }));

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box pt="90px">
      <Container maxWidth="lg">
        <Box maxWidth="70%">
          <Typography variant="body1">
            Big data and advanced analytics are creating profound new
            opportunities for policy makers and businesses. Ergo Analytics' strategy and
            analytical expertise aim to ensure that governmental organisations and
            businesses build the right capabilities to mine data for insights
            that can enable better decisions and create value.
          </Typography>
          {/* <img
            src="/assests/image.jpg"
            style={{ width: "100%", paddingTop: "40px", paddingBotton: "15px" }}
          ></img>
          <Box pb={2}>
            <Typography variant="body1">
              Ok, that is enough talking about myself in the third person.
            </Typography>
          </Box>
          <Box pb={2}>
            <Typography variant="body1">
              I have been a digital marketer now for a while and it has given me
              even more love for this work. I have worked agency side, client
              side, as a consultant and now as a freelancer. Spanning across a
              number of industries from iGaming and eSports to retail and
              finance. No two industries are the same, but that is why I put
              such a high importance on running digital audits to be able to
              design the best digital strategy for you.
            </Typography>
          </Box>
          <Box pb={3}>
            <Typography variant="body1">Feel free to contact me if:</Typography>
          </Box>
          <Box>
            <List>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="body1">
                  You are unsure what else you could do to improve your online
                  sales;
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="body1">
                  Launching a new business or product;
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="body1">
                  Expanding to other markets;
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="body1">
                  Looking for a digital marketing agency but more personalised.
                </Typography>
              </ListItem>
            </List>
          </Box> */}
        </Box>
      </Container>
    </Box>
  );
};
export default Contact;
