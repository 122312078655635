const WritingsList = [
  {
    date: "November 4, 2021",
    title: "Why Machine Learning Fairness is Important in Marketing",
    desc: "Have you ever browsed for a product and later saw that same product in a sponsored ad or watched a video on YouTube just because it was in the recommended videos? Have you ever wondered how the content that appeals to you always seems to find its way to you?",
  },
  // {
  //   date: "October 16, 2020",
  //   title:
  //     "WTF is the difference between digital marketing and growth marketing? There are 5 differences.",
  //   desc: "Growth marketing or growth hacking, is often used interchangeably with digital marketing but there are many noticeable differences. I too used to get them confused but here is a clear distinction between them. ",
  // },
  // {
  //   date: "September 15, 2020",
  //   title: "How to calculate your campaign reach",
  //   desc: "When you plan a campaign, it is always great to know what your goals are and once the campaign has finished, whether you reached your goals. This is an explanation and first steps on how to calculate your campaign reach. ",
  // },
  // {
  //   date: 'September 11, 2020',
  //   title: 'The difference between Zero, First, Second and Third party data',
  //   desc:
  //     'There are four types of data and each one of them adds value. An explanation on what each one of them is, some examples and their benefits. ',
  // },
  // {
  //   date: 'September 10, 2020',
  //   title:
  //     'Proud to be a mentor for Malta Information Technology Agency startup Accelerator programme',
  //   desc:
  //     'MITA is now commencing its 6th YouStartIT Accelerator Programme, and I am happy to announce that I will be part of it as one of the startup mentors.',
  // },
  // {
  //   date: 'September 9, 2020',
  //   title: 'Benefits of engaging a digital marketing consultant',
  //   desc:
  //     ' Hiring a consultant can offer a number of benefits such as; validation, resources, different perspective and providing certainty in an uncertain world. ',
  // },
  // {
  //   date: 'September 7, 2020',
  //   title: '6 not so obvious ways to increase your view count',
  //   desc:
  //     'Best practise on how to improve your video views, using a number of video marketing SEO tips, starting a dialogue, content creation and video editing. ',
  // },
  // {
  //   date: 'September 1, 2020',
  //   title: 'Should you upload your video content to Facebook or YouTube?',
  //   desc:
  //     'When starting a video blog on YouTube or Facebook, here are a number of strategic tips to consider. Think about why are you doing this, who are you targeting, is the messaging long or short-term and do you want to reach as many people as possible or do you want it searchable so people can find it later. I also touch upon what a video view is and the impact on your view count. ',
  // },
  // {
  //   date: 'August 14, 2020',
  //   title:
  //     'Digital marketing scheme launched to help grow business internationally',
  //   desc:
  //     'I wrote an article on Times of Malta about TradeMalta Export Response scheme which finances digital marketing expenses. It is great to see this fund exists. It is not only highlighting the importance and value of digital marketing, but it also demonstrates just how imperative it is for businesses aiming to grow internationally. Receiving funding for some of your media costs is a great help, but it is also important to consider how you will be using these funds.',
  // },
];

export default WritingsList;
