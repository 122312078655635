import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    root: {
        // minWidth: '350px',
        borderRadius: 5,
        border: 0,
        color: 'white',
        height: '50px',
        boxShadow: 'none',
        textTransform: 'inherit',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: '100%'
        },
        '& .MuiInputBase-root':{
            height : '100%',
        }
    },
    
    input: {
        padding : '1rem',
        height : '100%',
        fontSize: '1rem',
        fontWeight: 400,
        "&::-webkit-input-placeholder": {
            color: theme.palette.grey[900],
            textOverflow: "ellipsis !important",
        }
    }
}));

export default function ThemeTextField({ children, ...rest }) {
    const classes = useStyles();

    return (
        <TextField
            {...rest}
            classes={{
                root: classes.root,
                containedPrimary: classes.containedPrimary,
                containedSecondary: classes.containedSecondary,
                outlined: classes.outlined,
                // text: classes.text

            }}
            InputProps={{
                classes: {
                    input: classes.input
                },
            }}
            
        >
            {children}
        </TextField>
    );
}

// import React from "react";
// import { useTheme, withStyles } from "@material-ui/core/styles";
// import { TextField as TextFieldBase } from "@material-ui/core";

// function ThemeTextField({
//     children,
//     colorBrightness,
//     color,
//     ...props
// }) {
//     var theme = useTheme();

//     var Styled = createStyled({
//         baseStyle: {
//             fontFamily: "Montserrat",
//             fontStyle: "normal",
//             fontWeight: "normal",
//             fontSize: "18px",
//             lineHeight: "140.62%",
//             color: "rgba(0, 0, 0, 0.5)",
//             padding: 0,
//             height: '50px',
//             borderColor: theme.palette.common.secondary,
//         },
//         root: {
//             borderColor: theme.palette.common.secondary
//         }
//     });


//     function createStyled(styles, options) {
//         var Styled = function (props) {
//             const { children, ...other } = props;
//             return children(other);
//         };

//         return withStyles(styles, options)(Styled);
//     }


//     return (
//         <Styled>
//             {({ classes }) => (
//                 <TextFieldBase
//                     classes={{
//                         root: classes.root
//                     }}
//                     className={classes.baseStyle}
//                     {...props}
//                 >
//                     {children}
//                 </TextFieldBase>
//             )}
//         </Styled>
//     );
// }

// export default ThemeTextField;