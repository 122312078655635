import React from 'react';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { Typography as TypographyBase } from '@material-ui/core';
import clsx from 'clsx';

function ThemeTypography({ children, ...props }) {
  var theme = useTheme();

  var Styled = createStyled({
    // when primary color is needed in darker shade default is main
    colorPrimary: {},
    colorSecondery: {},
    colorTextSecondery: {},
    colorTextPrimary: {
      color: theme.palette.primary.dark,
    },
    // dynamic Classes
    customColor: {
      color: theme.palette.common[props.color],
    },
    grey: {
      color: theme.palette.grey[props.grey],
    },
    fontSize: {
      fontSize: props.fontSize,
    },
    fontStyle: {
      fontStyle: props.fontStyle,
    },
  });

  function createStyled(styles, options) {
    var Styled = function (props) {
      const { children, ...other } = props;
      return children(other);
    };

    return withStyles(styles, options)(Styled);
  }

  return (
    <Styled>
      {({ classes }) => (
        <TypographyBase
          classes={{
            colorTextPrimary: classes.colorTextPrimary,
          }}
          className={clsx({
            [classes.customColor]: props.color,
            [classes.grey]: props.grey,
            [classes.fontSize]: props.fontSize,
            [classes.fontStyle]: props.fontStyle,
          })}
          {...props}
        >
          {children}
        </TypographyBase>
      )}
    </Styled>
  );
}

export default ThemeTypography;
