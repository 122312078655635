const LinksList = [
  {
    name: "Home",
    url: "/home",
  },
  {
    name: "Writings",
    url: "/writings",
  },
  {
    name: "Case studies",
    url: "/case_studies",
  },
  {
    name: "About",
    url: "/about",
  },
];

export default LinksList;
