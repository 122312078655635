/*eslint-disable*/
import React from "react";
/*eslint-disable*/
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import Badge from "@material-ui/core/Badge";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import logo from "./logo.png";
import HeaderLinks from "./HeaderLinks";
import "./styles.css";
const useStyles = makeStyles((theme) => ({
  appBar: {
    display: "flex",
    flexWrap: "wrap",
    alignItem: "center",
    borderRadius: "3px",
    paddingBottom: "0 !important",
    width: "100%",
    backgroundColor: "transparent",
    boxShadow: "none",
    transition: "all 150ms ease 0s",
    justifyContent: "center",
    position: "relative",
    zIndex: "unset",
    float: "right !important",
    "& .MuiToolbar-root": {
      justifyContent: "flex-end",
      flexWrap: "wrap",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiToolbar-root": {
        justifyContent: "flex-start",
        flexWrap: "wrap",
      },
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    paddingRight: "5.1rem",
    alignItems: "space-between",
  },
  fixed: {
    position: "fixed",
  },
  logo: {
    maxWidth: 200,
    marginRight: "50vh",
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes.fixed]: true,
  });

  return (
    <React.Fragment>
      <AppBar className={appBarClasses}>
        <Toolbar
          style={{ float: "right", margin: "auto" }}
          className={classNames(classes.toolbar)}
        >
          {/* <img  src={logo} alt="" className={classes.logo} /> */}
          <span className={classes.logo}>
            <span
              style={{
                borderRadius: "3px 0px 0px 3px",
                fontFamily: "Montserrat",
                position: "static",
                lineHeight: "100%",
                fontStyle: "normal",
                fontWeight: "800",
                fontSize: "1.3em",
                padding: "0 3px 5px 3px",
                background: "#FF5252",
                color: "#FFFFFF",
              }}
            >
              ergo
            </span>
            <span
              style={{
                borderRadius: "0px 3px 3px 0px",
                fontFamily: "Montserrat",
                position: "static",
                lineHeight: "100%",
                fontStyle: "normal",
                fontWeight: "800",
                fontSize: "1.3em",
                padding: "0 3px 5px 3px",
                background: "rgba(0, 0, 0, 0.80)",
                color: "#FFFFFF",
              }}
            >
              analytics
            </span>
          </span>
          <div className={classes.flex} />
          <HeaderLinks />
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
{
  /* <Typography variant="h6" className={classes.title}>
            ERGO ANALYTICS
        </Typography> */
}
