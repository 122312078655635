/*eslint-disable*/
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// react components for routing our app without refresh
import { Link, useLocation } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Hidden } from '@material-ui/core';
// core components
import LinksList from './LinksList';

const useStyles = makeStyles((theme) => ({
  list: {
    margin: 0,
    paddingLeft: '0',
    listStyle: 'none',
    paddingTop: '40px',
    paddingBottom: '40px',
    display: 'flex',
  },
  listItem: {
    position: 'relative',
    margin: '0 10px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: 'auto',
    padding: '0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '&:after': {
        width: 'calc(100% - 30px)',
        content: '',
        height: '1px',
        marginLeft: '15px',
      },
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    position: 'relative',
    padding: '0.45rem 0.9375rem',
    fontWeight: '600',
    letterSpacing: '-0.2px',
    fontSize: '28px',
    borderRadius: '3px',
    lineHeight: '1.25rem',
    textDecoration: 'none',
    margin: '0px',
    display: 'flex',
    flexWrap: 'wrap',

    ['@media (max-width:780px)']: {
      // eslint-disable-line no-useless-computed-key
      fontSize: '15px',
      padding: '0',
      fontWeight: '600',
    },
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '16px',
    //   fontWeight: '600',
    // },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  activeLink: {
    color: theme.palette.text,
    '& a': {
      fontWeight: '700',
      color: theme.palette.text.main,
      '&:hover': {
        color: theme.palette.text.main,
      },
    },
  },
}));

export default function HeaderLinks(props) {
  const classes = useStyles();

  const location = useLocation();
  const renderRightMenu = (
    <List className={classes.list}>
      {LinksList.map((link, index) => (
        <ListItem
          className={classNames({
            [classes.listItem]: true,
            [classes.activeLink]: link.url === location.pathname,
          })}
          key={index}
        >
          <Link to={link.url} className={classes.link}>
            {link.name}
          </Link>
        </ListItem>
      ))}
    </List>
  );

  return renderRightMenu;
}
