/*eslint-disable*/
import React from 'react';
import { Container, Link } from '@material-ui/core';
// core components

import { makeStyles } from '@material-ui/core/styles';
import MainFooter from './MainFooter';
import SubFooter from './SubFooter';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    padding: '40px',
    // padding: '60px 0 0 0',
    '& a': {
      color: theme.palette.secondary.main,
    },
    ['@media (max-width:958px)']: {
      padding: '0px',
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MainFooter/>
    </div>
  );
};

export default Footer;
