import React, { useEffect } from "react";
import {
  Grid,
  Container,
  Box,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import CaseStudiesList from "./CaseStudiesList";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  writingsList: {
    paddingLeft: "50px",
    width: "100%",
    "@media (max-width:1279px)": {
      paddingLeft: "0px",
      marginTop: "20px",
    },
  },
  horizontalRow: {
    width: "100%",
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  ListDetail: {
    paddingTop: "24px",
    paddingBottom: "96px",
    textDecoration: "none",
    color: theme.palette.secondary.main,
    "@media (max-width:1237px)": {
      paddingBottom: "45px",
    },
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const CaseStudies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = useStyles();
  return (
    <Box pt="90px" pl={5} pr={5}>
      <Container maxWidth="md">
        <Grid container>
          <Grid item lg={3}>
            <Typography variant="h3">Projects</Typography>
            <br></br>
            <Typography variant="body2">Some projects we have done.</Typography>
          </Grid>
          <Grid container item lg={9} className={classes.writingsList}>
            {CaseStudiesList &&
              CaseStudiesList.map((list2) => (
                <>
                  <hr className={classes.horizontalRow}></hr>
                  <Box>
                    <Link
                      // to="/case-studies/boston-link-digital-audit"
                      className={classes.ListDetail}
                    >
                      <Grid container item lg={12}>
                        <Grid item lg={6}>
                          <Box pr={3}>
                            <Typography variant="h6">{list2.title}</Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={6}>
                          <Typography variant="body2">
                            <span
                              dangerouslySetInnerHTML={{ __html: list2.desc }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Link>
                  </Box>
                </>
              ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default CaseStudies;
