/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useEffect, useState } from "react";
import "./transition.css";
import img1 from "../Logos/1.svg";
import img2 from "../Logos/2.svg";
import img3 from "../Logos/3.svg";
import img4 from "../Logos/4.svg";
import img5 from "../Logos/5.svg";
import img6 from "../Logos/6.svg";
import img8 from "../Logos/8.svg";
import img9 from "../Logos/9.svg";
import img10 from "../Logos/10.svg";
import img11 from "../Logos/11.svg";
import img12 from "../Logos/12.svg";
import img13 from "../Logos/13.svg";
import img14 from "../Logos/14.svg";
import img15 from "../Logos/15.svg";

import {
  Grid,
  Container,
  //List,
  //ListItem,
  Button,
  Box,
  Typography,
} from "@material-ui/core";
//import AddIcon from '@material-ui/icons/Add';
// import 'gifler';
import { makeStyles } from "@material-ui/core/styles";
import HomeList2 from "./HomeList2";
//import { FilterNone } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
  MainInfo: {
    color: theme.palette.primary.main,
    paddingLeft: "0px",
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-width:958px)"]: {
      paddingLeft: "0px",
    },
  },
  subInfo: {
    color: theme.palette.secondary.main,
  },
  subInfo2: {
    fontSize: 150,
    color: theme.palette.primary.main,
    paddingBottom: 5,
    "@media (max-width:600px)": {
      fontSize: 75,
    },
  },
  subInfo3: {
    color: theme.palette.primary.main,
    opacity: 1,
    transform: "translateY(0rem)",
    height: "100px",
  },
  subInfo4: {
    color: theme.palette.primary.main,
    opacity: 0,
    transform: "translateY(2rem)",
  },

  subInfo5: {
    color: theme.palette.primary.main,
    display: "none",
    opacity: 0,
    transform: "translateY(2rem)",
  },
  homeContent: {
    marginTop: "25rem",
    marginBottom: "25rem",
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-width:1240px)"]: {
      marginTop: "30rem",
      marginBottom: "30rem",
    },
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-width:960px)"]: {
      marginTop: "15rem",
      marginBottom: "15rem",
    },
  },
  image: {
    height: "5rem",
    maxWidth: "none",
    //objectFit: 'cover',
    marginLeft: "1rem",
    marginRight: "1rem",
    resizeMode: "contain",
  },
  ImageContainerRight: {
    // animation: 'marquee 2s linear infinite',
    whiteSpace: "nowrap",
    transform: "translateX(100%)",
    animation: "$floatText 15s infinite linear",
    // paddingLeft: '100vw',
  },
  "@keyframes floatText": {
    to: { transform: "translateX(-100%)" },
  },
  ImageContainerLeft: {
    // animation: 'marquee 2s linear infinite',
    whiteSpace: "nowrap",
    transform: "translateX(-100%)",
    animation: "$floatText1 15s infinite linear",
    // paddingRight: '100vw' /*Initial offset*/,
  },
  "@keyframes floatText1": {
    to: { transform: "translateX(100%)" },
  },
  // division: {
  //   overflow: 'hidden',
  //   position: 'relative',
  //   float: 'right',
  //   height: '65px',
  //   paddingTop: '10px',
  //   marginTop: '-10px',
  //   li: {
  //     display: 'block',
  //   },
  // },
  flip4: { animation: "$flip4 10s cubicBezier(0.23, 1, 0.32, 1.2) infinite" },
  "@keyframes flip4": {
    "0%": { marginTop: "-360px" },
    "5%": { marginTop: "-270px" },
    "25%": { marginTop: "-270px" },
    "30%": { marginTop: "-180px" },
    "50%": { marginTop: "-180px" },
    "55%": { marginTop: "-90px" },
    "75%": { marginTop: "-90px" },
    "80%": { marginTop: "0px" },
    "99.99%": { marginTop: " 0px" },
    "100%": { marginTop: "-270px" },
  },

  count: {
    fontSize: "160px",
    fontWeight: "900",
    color: theme.palette.primary.main,
  },
  countIcon: {
    fontSize: "150px",
    color: theme.palette.primary.main,
  },
  PlusIcon: {
    fontSize: "100px",
    color: theme.palette.primary.main,
    padding: 0,
  },
  horizontalRow: {
    width: "100%",
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  description: {
    color: "#ff5252 ",
    marginBottom: "320px",
  },
  homeList2: {
    paddingLeft: "50px",
    width: "100%",
    "@media (max-width:1279px)": {
      paddingLeft: "0px",
      marginTop: "20px",
    },
  },
  ListDetail: {
    paddingTop: "24px",
    paddingBottom: "96px",
    "@media (max-width:1237px)": {
      paddingBottom: "45px",
    },
  },
  whySection: {
    padding: "0px",
    "@media (max-width:1359px)": {
      padding: "20px",
    },
  },
  whatSection: {
    padding: "0px",
    "@media (max-width:1359px)": {
      padding: "20px",
    },
  },
  countSection: {
    display: "flex",
    flexWrap: "noWrap",
    "@media (max-width:1359px)": {
      padding: "20px",
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const inputRef = useRef();
  const inputRef2 = useRef();
  const Classname1 = useRef("hidee");
  const Classname2 = useRef("hidee");
  const Classname3 = useRef("hidee");

  const [, setPosition] = useState(0);
  const [showA, setshowA] = useState(false);
  const [showB, setshowB] = useState(false);
  const [showC, setshowC] = useState(false);
  const [showD, setshowD] = useState(false);
  const [icon1, seticon1] = useState("PlusIcon");
  const [icon2, seticon2] = useState("PlusIcon");
  const [icon3, seticon3] = useState("PlusIcon");
  const [icon4, seticon4] = useState("PlusIcon");
 
  let divstyle = {
    height: "fit-content",
    marginBottom: "30px",
    opacity: "0",
    position: "absolute",
    transition: "all 0.1s",
  };
  let divstyle2 = {
    position: "relative",
    height: "fit-content",
    marginBottom: "30px",
    opacity: "1",
    transition: "all 2s",
  };
  useEffect(() => {
    window.onscroll = () => {
      setPosition(window.pageYOffset);
      if (Classname1.current === "hidee") {
        if (window.pageYOffset > 2250) {
          Classname1.current = "showed1";
          Classname2.current = "showed2";
          Classname3.current = "showed3";
        }
      }
    };
  }, []);
  setInterval(() => {
    if (
      inputRef &&
      inputRef.current &&
      inputRef.current.innerText === "collect data"
    ) {
      inputRef.current.innerText = "get insights";
    } else if (
      inputRef &&
      inputRef.current &&
      inputRef.current.innerText === "get insights"
    ) {
      inputRef.current.innerText = "develop a data science pipeline";
    } else if (
      inputRef &&
      inputRef.current &&
      inputRef.current.innerText === "develop a data science pipeline"
    ) {
      inputRef.current.innerText = "collect data";
    }
  }, 4000);

  ///canvas animation
  // var images = new Array();
  // var currentLocation = 0;
  // var totalImages = 7;

  // for (var i = 0; i < totalImages; i++) {
  //   var img = new Image();
  //   switch (i) {
  //     case 0:
  //       img.src =
  //         'http://img.pokemondb.net/sprites/black-white/normal/mewtwo.png';
  //       break;
  //     case 1:
  //       img.src =
  //         'http://img.pokemondb.net/sprites/black-white/normal/keldeo-ordinary.png';
  //       break;
  //     case 2:
  //       img.src =
  //         'http://img.pokemondb.net/sprites/black-white/normal/darkrai.png';
  //       break;
  //     case 3:
  //       img.src =
  //         'http://floatzel.net/pokemon/black-white/sprites/images/5.png';
  //       break;
  //     case 4:
  //       img.src =
  //         'http://vignette1.wikia.nocookie.net/capx/images/0/03/001.png/revision/latest?cb=20140322003659';
  //       break;
  //     case 5:
  //       img.src =
  //         'http://img.pokemondb.net/sprites/black-white/normal/absol.png';
  //       break;
  //     case 6:
  //       img.src =
  //         'http://img.pokemondb.net/sprites/black-white/normal/dewgong.png';
  //       break;
  //     case 7:
  //       img.src =
  //         'http://orig05.deviantart.net/e770/f/2013/008/c/6/froakie_by_baconboy914-d5qvrjo.gif';
  //       break;
  //   }

  //   images.push(img);
  // }

  // var c = document.getElementById('background');
  // console.log('c', c);
  // var ctx = c.getContext('2d');

  // var mouseWheel = function () {
  //   window.addEventListener('mousewheel', function (e) {
  //     e.preventDefault(); // No scroll

  //     // The following equation will return either a 1 for scroll down
  //     // or -1 for a scroll up
  //     var delta = Math.max(-1, Math.min(1, e.wheelDelta));

  //     // This code mostly keeps us from going too far in either direction
  //     if (delta == -1) currentLocation += 1;
  //     if (delta == 1) currentLocation -= 1;
  //     if (currentLocation < 0) currentLocation = 0;
  //     if (currentLocation >= totalImages - 1) currentLocation = totalImages - 1;
  //     console.log('Current location ' + currentLocation);

  //     // See below for the details of this function
  //     setImage(currentLocation);
  //   });
  // };

  // var setImage = function (newLocation) {
  //   // drawImage takes 5 arguments: image, x, y, width, height
  //   ctx.fillRect(0, 0, c.width, c.height);
  //   ctx.drawImage(images[newLocation], 0, 0, 150, 150);
  // };

  // images[0].onload = function () {
  //   ctx.fillRect(0, 0, c.width, c.height);
  //   ctx.drawImage(images[currentLocation], 0, 0, 150, 150);
  //   mouseWheel();
  // };

  //const [text, setText] = React.useState(1);

  // React.useEffect(() => {
  //   setInterval(() => {
  //     if (text === 1) {
  //       inputRef.current.setAttribute(
  //         'style',
  //         'display:block;opacity:1;transform: translateY(0rem);'
  //       );
  //       inputRef2.current.setAttribute(
  //         'style',
  //         'display:none;opacity:0;transform: translateY(2rem);'
  //       );
  //       inputRef3.current.setAttribute(
  //         'style',
  //         'display:none;opacity:0;transform: translateY(2rem);'
  //       );
  //     }
  //     if (text === 2) {
  //       inputRef2.current.setAttribute(
  //         'style',
  //         'display:block;opacity:1;transform: translateY(0rem);'
  //       );
  //       inputRef.current.setAttribute(
  //         'style',
  //         'display:none;opacity:0;transform: translateY(2rem);'
  //       );
  //       inputRef3.current.setAttribute(
  //         'style',
  //         'display:none;opacity:0;transform: translateY(2rem);'
  //       );
  //     }
  //     if (text === 3) {
  //       inputRef3.current.setAttribute(
  //         'style',
  //         'display:block;opacity:1;transform: translateY(0);'
  //       );
  //       inputRef.current.setAttribute(
  //         'style',
  //         'display:none;opacity:0;transform: translateY(2rem);'
  //       );
  //       inputRef2.current.setAttribute(
  //         'style',
  //         'display:none;opacity:0;transform: translateY(2rem);'
  //       );
  //     }
  //     {
  //       text === 3 ? setText(1) : setText(text + 1);
  //     }
  //   }, 3000);
  // }, [text]);
  // function toggleText() {
  //   var x = document.getElementById('toggleTexts').innerHTML;
  //   if (x && x === `enter a new market`) {
  //     x.innerHTML = 'increase your international presence';
  //   } else if (x && x == 'launch a new product') {
  //     x.innerHTML = 'enter a new market';
  //   }
  // }
  // setInterval(() => {
  //   toggleText();
  // }, 9000);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <Box
      pt="90px"
      ref={inputRef2}
      style={{
        flex: 1,
        background: "url(/assets/bg-dots.png),url(/assets/bg-dots2.png)",
        backgroundPosition: "100% 0%,0% 10%",
        backgroundSize: "60% 15%",
        backgroundRepeat: "no-repeat,no-repeat",
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          wrap="wrap"
          className={classes.HomeMain}
        >
          {/* <Grid item lg={3} md={3}>
            <Box p="20px">
              {' '}
              <canvas id="background" width="150" height="150"></canvas>
            </Box>
          </Grid> */}
          <Grid item>
            <Box className={classes.MainInfo}>
              <Box>
                <Box>
                  <Typography variant="h1" className={classes.subInfo2}>
                    Comfort is bad for business.
                    <br></br>
                  </Typography>
                  <Typography variant="h2" className={classes.subInfo}>
                    Ergo Analytics is here to <br></br>help your organisation
                    <br />
                  </Typography>
                </Box>
                {/* <div className={classes.flip4}> */}

                <Typography
                  variant="h1"
                  ref={inputRef}
                  className={classes.subInfo3}
                  id="trans"
                ></Typography>
                {/* <Typography
                    variant="h1"
                    ref={inputRef2}
                    className={classes.subInfo4}
                  >
                    increase your international presence
                  </Typography>
                  <Typography
                    variant="h1"
                    className={classes.subInfo5}
                    ref={inputRef3}
                  >
                    launch a new product
                  </Typography>
                </div> */}
                {/* <div className={classes.division}>
                  <ul class="flip4">
                    <li>Oh My!</li>
                    <li>Swoosh</li>
                    <li>Cool</li>
                    <li>Awesome</li>
                  </ul>
                </div> */}
              </Box>

              <div className={classes.homeContent}>
                <Typography variarnt="body1">
                  Big data and advanced analytics are creating profound new
                  opportunities for policy makers and businesses. Ergo
                  Analytics' strategy and analytical expertise aim to ensure
                  that governmental organisations and businesses build the right
                  capabilities to mine data for insights that can enable better
                  decisions and create value.
                </Typography>
                <br></br>
                <Typography variarnt="body1">
                  Our goal is to be an extension of your team, challenge
                  assumptions, bring you the knowledge needed to achieve your
                  goals, and build a data science strategy that's right for your
                  business.
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box textAlign="center" pt={4} pb={12} color="#193c48">
        <Typography variant="h2">Technologies we work on</Typography>
      </Box>
      <Box mb={60} maxWidth="100%">
        <Box overflow="hidden" maxWidth="100%">
          <Box
            display="flex"
            justifyContent="space-around"
            flexDirection="row"
            flexWrap="noWrap"
            className={classes.ImageContainerRight}
            pb={12}
          >
            <img src={img1} className={classes.image}></img>
            <img src={img2} className={classes.image}></img>
            <img src={img3} className={classes.image}></img>
            <img src={img4} className={classes.image}></img>
            <img src={img5} className={classes.image}></img>
            <img src={img6} className={classes.image}></img>
            {/* <img src="/assets/logos/7.svg" className={classes.image}></img> */}
            <img src={img8} className={classes.image}></img>
          </Box>
        </Box>

        <Box overflow="hidden">
          <Box
            display="flex"
            justifyContent="space-around"
            flexDirection="row"
            flexWrap="noWrap"
            className={classes.ImageContainerLeft}
            pb={3}
          >
            <img src={img9} className={classes.image}></img>
            <img src={img10} className={classes.image}></img>
            <img src={img11} className={classes.image}></img>
            <img src={img12} className={classes.image}></img>
            <img src={img13} className={classes.image}></img>
            <img src={img14} className={classes.image}></img>
            <img src={img15} className={classes.image}></img>
          </Box>
        </Box>
      </Box>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item lg={4} md={4} sm={4}>
            <Box className={Classname1.current}>
              <span className={classes.count}>03</span>
              <span className={classes.countIcon}>+</span>
              <br></br>
              <br></br>
              <Typography variant="h3">Apps</Typography>
              <br></br>
              <Typography variant="body2">
                We are building three data centric apps. All coming soon.
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={4}>
            <Box className={Classname2.current}>
              <span className={classes.count}>20</span>
              <span className={classes.countIcon}>+</span>
              <br></br>
              <br></br>
              <Typography variant="h3">Data Science Projects</Typography>
              <br></br>
              <Typography variant="body2">
                We have worked on countless data science projects spanning from
                NLP, numerous other machine learning applications such as in marketing, pricing, identity fraud, as well as geostatistics and map
                visualisations.
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={4}>
            <Box className={Classname3.current}>
              <span className={classes.count}>09</span>
              <span className={classes.countIcon}>+</span>
              <br></br>
              <br></br>
              <Typography variant="h3">Years of Experience</Typography>
              <br></br>
              <Typography variant="body2">
                Wealth of experience working for a big company and several
                startups.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box mt={40} mb={40} className={classes.whatSection}>
          <Grid container direction="row" wrap="wrap">
            <Grid item lg={3}>
              <Typography variant="h3">What we can provide</Typography>
              <br></br>
              <Typography variant="body2">
                Grow your business using your data and insights gained from it.
                We will manage your data projects from ideation to completion.
              </Typography>
            </Grid>
            <Grid item lg={9} className={classes.homeList2}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                pb={12}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box pr={5}>
                    <Typography variant="body1">01</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h2">Data collection plan</Typography>
                  </Box>
                </Box>
                <Box>
                  <Button
                    style={{
                      background: "transparent",
                      border: "white",
                      outline: "none",
                      color: "red",
                      fontSize: "3em",
                    }}
                    onClick={() => {
                      if (icon1 === "PlusIcon") {
                        seticon1("CrossIcon");
                      }
                      if (icon1 === "CrossIcon") {
                        seticon1("PlusIcon");
                      }
                      if (!showA) {
                        setshowA(true);
                      } else {
                        setshowA(false);
                      }
                    }}
                    className={icon1}
                  >
                    +
                  </Button>
                </Box>
              </Box>
              <Box style={showA ? divstyle2 : divstyle}>
                <li>Audit your existing methods</li>
                <li>
                  Identify issues and/or opportunities for collecting data
                </li>
                <li>Plan an approach and new methods.</li>
                <li>
                  Acquire data from primary or secondary data sources and
                  maintain databases/data systems
                </li>
              </Box>
              <hr className={classes.horizontalRow}></hr>

              <br />
              <br />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                pb={12}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box pr={5}>
                    <Typography variant="body1">02</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h2">Data analytics</Typography>
                  </Box>
                </Box>
                <Box>
                  <Button
                    style={{
                      background: "transparent",
                      border: "white",
                      outline: "none",
                      color: "red",
                      fontSize: "3em",
                    }}
                    onClick={() => {
                      if (!showB) {
                        setshowB(true);
                      } else {
                        setshowB(false);
                      }
                      if (icon2 === "PlusIcon") {
                        seticon2("CrossIcon");
                      }
                      if (icon2 === "CrossIcon") {
                        seticon2("PlusIcon");
                      }
                    }}
                    className={icon2}
                  >
                    +
                  </Button>
                </Box>
              </Box>
              <Box style={showB ? divstyle2 : divstyle}>
                <li>
                  Interpret data, analyze results using statistical techniques
                  and provide reports
                </li>
                <li>
                  Identify, analyze, and interpret trends or patterns in complex
                  data sets Filter and “clean” data
                </li>
                <li>
                  Work with client to prioritize business and information needs
                </li>
                <li>Locate and define new process improvement opportunities</li>
              </Box>
              <hr className={classes.horizontalRow}></hr>

              <br />
              <br />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                pb={12}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box pr={5}>
                    <Typography variant="body1">03</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h2">Project management</Typography>
                  </Box>
                </Box>
                <Box>
                  <Button
                    style={{
                      background: "transparent",
                      border: "white",
                      outline: "none",
                      color: "red",
                      fontSize: "3em",
                    }}
                    onClick={() => {
                      if (!showC) {
                        setshowC(true);
                      } else {
                        setshowC(false);
                      }
                      if (icon3 === "PlusIcon") {
                        seticon3("CrossIcon");
                      }
                      if (icon3 === "CrossIcon") {
                        seticon3("PlusIcon");
                      }
                    }}
                    className={icon3}
                  >
                    +
                  </Button>
                </Box>
              </Box>
              <Box style={showC ? divstyle2 : divstyle}>
                <li>Set project goals and priorities</li>
                <li>Data acquisition and prep</li>
                <li>Research and development</li>
                <li>Validation</li>
                <li>Delivery</li>
                <li>Monitoring</li>
              </Box>
              <hr className={classes.horizontalRow}></hr>
              <br />
              <br />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                pb={12}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box pr={5}>
                    <Typography variant="body1">04</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h2">Consultancy</Typography>
                  </Box>
                </Box>
                <Box>
                  <Button
                    style={{
                      background: "transparent",
                      border: "white",
                      outline: "none",
                      color: "red",
                      fontSize: "3em",
                    }}
                    onClick={() => {
                      if (!showD) {
                        setshowD(true);
                      } else {
                        setshowD(false);
                      }
                      if (icon4 === "PlusIcon") {
                        seticon4("CrossIcon");
                      }
                      if (icon4 === "CrossIcon") {
                        seticon4("PlusIcon");
                      }
                    }}
                    className={icon4}
                  >
                    +
                  </Button>
                </Box>
              </Box>
              <Box style={showD ? divstyle2 : divstyle}>
                <li>Training your staff</li>
                <li>Find the right agency for you and your needs</li>
                <li>Interim Digital Specialist</li>
                <li>Flexible attention based on your needs</li>
              </Box>
              <hr className={classes.horizontalRow}></hr>
            </Grid>
          </Grid>
        </Box>
        <Box
          style={{ position: "relative" }}
          p={5}
          className={classes.description}
        >
          <div id="faded">
            <Typography variant="h6">
              <a href="mailto:info@ergonanalyticscc.com">Say Hello</a>
            </Typography>
          </div>
          <Typography variant="h5">
            Strategies can be created for all budgets, it is important to start
            off small, understand what works, adjust, and then scale.
          </Typography>
        </Box>

        <Box className={classes.whySection}>
          <Grid container>
            <Grid item lg={3}>
              <Typography variant="h3">
                Why engage a data Science consultant?
              </Typography>
              <br></br>
              <Typography variant="body2">
                No need to get a full time data specialist on board, hire only
                when you need one. Resulting in less costs, higher expertise ,
                and a broader knowledge from across industries.
              </Typography>
            </Grid>
            <Grid container item lg={9} className={classes.HomeList2}>
              {HomeList2 &&
                HomeList2.map((list2) => (
                  <>
                    <hr className={classes.horizontalRow}></hr>
                    <Box className={classes.ListDetail}>
                      <Grid container item spacing={2} lg={12}>
                        <Grid item lg={1}>
                          <Typography variant="body1">
                            {list2.number}
                          </Typography>
                        </Grid>
                        <Grid item container lg={11}>
                          <Grid item lg={7} md={6}>
                            <Typography variant="h2">{list2.title}</Typography>
                          </Grid>
                          <Grid item lg={5} md={5}>
                            <Typography variant="body2">
                              {list2.desc}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ))}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};
export default Home;
